/*module.exports = {
    endpointUrl: "http://localhost:3000/",
    user: "user",
    request: "request",
    category: "category",
    backendTimeout: 5000,
    frontEndVersion: "1.0.0.0"
}*/
module.exports = {
    endpointUrl: "https://www.service-galileotp.com/backend/",
    user: "user",
    auth: "auth",
    request: "request",
    category: "category",
    image: "image",
    backendTimeout: 40000,
    frontEndVersion: "1.0.0.0"
}