import React from "react";
import endpoints from "../../services/endpoints";
import styles from './Request.module.css';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

class Request extends React.Component{
    constructor(props){
        super(props); 
        this.state = {
            req_uuid: props.request.richiesta_uuid,
            req_catgegory_name: props.request.categoria_nome,
            req_category_description: props.request.categoria_descrizione,
            req_created: props.request.richiesta_created,
            req_modified: props.request.richiesta_modified,
            req_status: props.request.richiesta_stato,
            req_description: props.request.richiesta_descrizione,
            req_ref: props.request.richiesta_riferimento,
            req_imgs: props.request.richiesta_immagini,
            req_date_viewed: props.request.richiesta_data_visionata,
            req_date_accepted: props.request.richiesta_data_presa_in_carico,
            req_date_completed: props.request.richiesta_data_conclusa,
            req_phone: props.request.richiesta_contatto_telefonico,
            expanded: false,
        };
        this.collapse = this.collapse.bind(this);
    }
    collapse(){
        if(this.state.expanded === true){
            this.setState({
                expanded: false
            });
        }else{
            this.setState({
                expanded: true
            });
        }
    }
    render(){
        let style = "none";
        let extraClass = styles.collapsible;
        if(this.state.expanded){
            style= "block";
            extraClass = `${styles.collapsible} ${styles.active}`;
            //extraClass = styles.collapsible + " " + styles.active;
        }
        let request_status = <div style={{display: 'inline-block', borderRadius: '5px', padding: '4px', marginRight: '20px', color: 'white', fontWeight: 'bold', backgroundColor: '#3399ff'}}> New</div>
        let conclusionInformation = "";
        let fixedCDate = "";
        let isCanceled = false;
        switch(this.state.req_status){
            case "inserita":
                request_status = <div style={{display: 'inline-block', borderRadius: '5px', padding: '4px', marginRight: '20px', color: 'white', fontWeight: 'bold', backgroundColor: '#3399ff'}}> New</div>
                break;
            case "visionata":
                request_status = <div style={{display: 'inline-block', borderRadius: '5px', padding: '4px', marginRight: '20px', color: 'black', fontWeight: 'bold', backgroundColor: 'rgb(255, 177, 15)'}}> Viewed</div>
                break;
            case "presa":
                request_status = <div style={{display: 'inline-block', borderRadius: '5px', padding: '4px', marginRight: '20px', color: 'white', fontWeight: 'bold', backgroundColor: '#59B521'}}> Accepted</div>
                break;
            case "attesa":
                request_status = <div style={{display: 'inline-block', borderRadius: '5px', padding: '4px', marginRight: '20px', color: 'white', fontWeight: 'bold', backgroundColor: '#63768d'}}> Waiting</div>
                break;
            case "in_chiusura":
                request_status = <div style={{display: 'inline-block', borderRadius: '5px', padding: '4px', marginRight: '20px', color: 'white', fontWeight: 'bold', backgroundColor: '#77fc77'}}> Completing</div>
                break;
            case "conclusa":
                request_status = <div style={{display: 'inline-block', borderRadius: '5px', padding: '4px', marginRight: '20px', color: 'white', fontWeight: 'bold', backgroundColor: '#045f04'}}> Completed</div>
                if( this.state.req_date_completed!=null){
                    let fixedCDateArr = this.state.req_date_completed.split(":");
                    fixedCDate= fixedCDateArr[0]+":"+fixedCDateArr[1];
                    conclusionInformation = <b>Completed in date: </b>;
                }
                break;
            case "annullata":
                request_status = <div style={{display: 'inline-block', borderRadius: '5px', padding: '4px', marginRight: '20px', color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(174, 174, 174)'}}> Canceled</div>
                if( this.state.req_date_completed!=null){
                    let fixedADateArr = this.state.req_date_completed.split(":");
                    fixedCDate = fixedADateArr[0]+":"+fixedADateArr[1];
                    conclusionInformation = <b>Canceled in date: </b>; 
                }
                isCanceled = true;
                break;
            default:
                request_status = <div style={{display: 'inline-block', borderRadius: '5px', padding: '4px', marginRight: '20px', color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(174, 174, 174)'}}> Canceled</div>
                break;
        }
        
        let fixedDateArr = this.state.req_created.split(":");
        let fixedDate= fixedDateArr[0]+":"+fixedDateArr[1];

        let imageDiv = <></>;
        if(this.state.req_imgs!=null && this.state.req_imgs!= undefined){
            let images = this.state.req_imgs.split(",");
            imageDiv = <div>
                <br/> 
                <b>Attached pictures:</b>
                <br/>
                {images.map((element) =>    
                <Zoom style={{margin: "3px", border: "3px right" }}>
                    <div style={{fontSize: "x-small", height: '100px', width: '100px', textAlign: 'center',  backgroundColor: '#d0d0d0', padding: '5px', margin: '3px', borderRadius: '5px'}}>
                    <img style={{maxWidth: '100px', maxHeight: '100px', position: 'absolute', top: "50%", left:"50%", transform: "translate(-50%,-50%)"}} alt={element} src={endpoints.endpointUrl+endpoints.image+"/"+element} />
                    </div>
                </Zoom> )}
                <br/>
                </div>
            if(this.state.req_status === "conclusa"){
                imageDiv = <div><br/><b>Attached pictures:</b><br/><i>Once a request is completed, the attached pictures are removed.</i><br/></div>
            }
            if(this.state.req_status === "annullata"){
                imageDiv = <div><br/><b>Attached pictures:</b><br/><i>Once a request is canceled, the attached pictures are removed.</i><br/></div>
            }
        }

        return ( 
        <div>
            <button type="button" onClick={this.collapse} className={extraClass}>
            { isCanceled ? <div style={{textDecoration: "line-through", display: "inline"}}>{request_status} {fixedDate} - <b>{this.state.req_catgegory_name}</b> </div> : <> {request_status} {fixedDate} - <b>{this.state.req_catgegory_name}</b></>}
            </button>
            <div style={{display: style}} className={styles.content}>
              <b>UUID:</b> <u style={{marginBottom: '3px'}}>{this.state.req_uuid}</u><br/>
              <b>Ref:</b> <u style={{marginBottom: '3px'}}>{this.state.req_ref}</u><br/>
              <b>Problem category description:</b><br/>  
              <u style={{margin: '3px'}}>{this.state.req_category_description}</u><br/>
              {(this.state.req_status === "conclusa" || this.state.req_status === "annullata")? <>{conclusionInformation}{fixedCDate}<br/></> :<></>}
              <b>Problem Description:</b> <br/>{this.state.req_description} <br/>
              {this.state.expanded? <> {imageDiv} <br/> </> : <></>}

              
            </div> 
        </div>
        );
    }

}

export default Request;