const axios = require('axios').default;
const endpoints =  require("./endpoints.js");
const jsReplacer = require("../utils/jsReplacer.js");

module.exports = {
    getRequests: function getRequests(){
        return axios.request({
            url: endpoints.request,
            timeout: endpoints.backendTimeout,
            method: 'get',
            baseURL: endpoints.endpointUrl,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    postRequests: function postRequests(id_cat,description,phone, reference){
        return axios.request({
            url: endpoints.request,
            timeout: endpoints.backendTimeout,
            method: 'post',
            baseURL: endpoints.endpointUrl,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                id_categoria: id_cat,
                descrizione: description,
                telefono: phone,
                riferimento: reference                
            },jsReplacer.jsonStringifyReplacer),
        });
    },

    postRequestsWPic: function postRequestsWPic(id_cat,description,phone,reference,files){
        const json = JSON.stringify({
            id_categoria: id_cat,
            descrizione: description,
            telefono: phone,
            riferimento: reference,
            immagini: files
        },jsReplacer.jsonStringifyReplacer);
       
        return axios.request({
            url: endpoints.request,
            timeout: endpoints.backendTimeout,
            method: 'post',
            baseURL: endpoints.endpointUrl,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: json
        });
    }
}