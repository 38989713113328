import React from 'react';
import { Navigate } from 'react-router-dom';
import RequestList from '../../components/RequestList/RequestList';
import styles from './RootView.module.css';

class RootView extends React.Component {constructor(props) {
    super(props);
    this.state = {
      authenticated: true,
      displayRequests: false,
      newReq: false,
    }
    this.addNewRequest = this.addNewRequest.bind(this);

  }
  componentDidMount(){
    if (localStorage.getItem("userData") === undefined) {
        this.setState({ authenticated: false }); 
        return;
    }
    if (localStorage.getItem("userData") === null) {
       this.setState({ authenticated: false }); 
       return;
    }
    if(this.state.authenticated){
      this.setState({displayRequests: true});
    }
  }  
  addNewRequest(){
    this.setState({newReq: true});
  }
  render() {
    const  redirect  = !this.state.authenticated;
    console.log(redirect);
    if (redirect) {
        return <Navigate to='/login'/>;
    }
    if(this.state.newReq){
      return <Navigate to='/request'/>;
    }
    return (
        <div className={styles.fullDiv} style={{height:'100%'}}>
          <div className={styles.titleBar}>
              <div className={styles.title} style={{width: '100%', backgroundColor: 'white', fontWeight: "bold", fontSize: 'large', textAlign: "left"}}>
                <p style={{paddingLeft: '20px'}}>Requests List</p>
              </div>
              <div className={styles.buttonDiv}>
                <button className={styles.button} onClick={this.addNewRequest}> Add new Request</button>
              </div>
          </div>
          {this.state.displayRequests ? <RequestList /> : <></>}
        </div>
      );
    }
  }


  export default RootView;