import React from "react";
import { Navigate } from "react-router-dom";
import Request from "../Request/Request";
const requestServices = require( '../../services/requestServices');


class RequestList extends React.Component {
    constructor(){
        super();
        this.state = {
            requests: [],
            noRequests: false,
            goToLogin: false
        }
    }
    componentDidMount(){
        requestServices.getRequests()
        .then((response) => {
            if(response.status === 200){
                this.setState({requests: response.data.content});
            }else{
                alert(response.data.message);
            }
        })
        .catch((error)=>{
            //console.log(error);
            if (error.response.status === 401) {
                localStorage.removeItem("userData");
                alert("Your session has expired, please login again");
                this.setState({goToLogin: true});
            }else if(error.response.status === 404){
                //localStorage.removeItem("userData");
                //window.location.reload();
               //console.log("No requests for user");
               this.setState({noRequests: true});
            }else{
                localStorage.removeItem("userData");
                alert("Error processing your request list, please log in again.");
            }
        });

    }
    render(){
        if(this.state.goToLogin){
            return <Navigate to='/login'/>;
        }
        if(this.state.requests===undefined){
            return;
        }
        /*if(this.state.requests===[]){
            return (<div style="width: 100%; text-align: center;"><p style={{color: "white", fontWeight: "bold"}}>You have no requests yet!</p></div>);
        }*/

        const noRqst = <div style={{width: "100%", textAlign: "center"}}><p style={{color: "white", fontWeight: "bold"}}>You have no requests yet!</p></div>;

        const rst = this.state.requests.map((element) =>    
        <Request  request={element} />  );

        return(
            <div style={{overflow: 'auto', height: '100%'}}>
                { this.state.noRequests ? noRqst : rst }
            </div>
        );
    }

}
export default RequestList;