import React from 'react';
import { Navigate } from 'react-router-dom';
import LoginForm from '../../components/LoginForm/LoginForm.js';
import styles from './LoginView.module.css';

class LoginView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      redirect: false,
    }
  }

    componentDidMount(){
      if (localStorage.getItem("userData") !== undefined) {
           if (localStorage.getItem("userData") !== null) {
             this.setState({redirect: true});
           }
        }
    }
    render() {
      if(this.state.redirect){
        return (
          <Navigate to="/" />
        );
      }
      return (
        <div className={styles.fullDiv} style={{height:'100%'}}>
          <LoginForm />
        </div>
      );
    }
  }


  export default LoginView;