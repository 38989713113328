import React from "react";
import styles from './RequestForm.module.css';
import { Navigate } from 'react-router-dom';
import Compressor from "compressorjs";
import Modal from 'react-modal';
import { category } from "../../services/endpoints";
const requestServices = require('../../services/requestServices');
const categoryServices = require('../../services/categoryServices');

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      textAlign: 'center',
      transform: 'translate(-50%, -50%)',
    },
};
Modal.setAppElement('#root');

class RequestForm extends React.Component {
    constructor(props) {
        super(props);
        let currentFile = null;
        this.state = {
            modalIsOpen: false,
            success: false,
            confirmed: false,
            category: 1,
            ccDesc: "",
            description: '',
            phone_number: '',
            categories: null,
            goToRequest: false,
            reference: '',
            files: [
                null,
            ],
            lock: false,
            goToLogin: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.fileChanged = this.fileChanged.bind(this);
        this.loadFile = this.loadFile.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }
    

    openModal() {
        this.setState({
            modalIsOpen: true
        });
    }
    
    closeModal() {
        this.setState({
            modalIsOpen: false
        });
    }

    loadFile(event){
        if(this.state.lock){
            event.preventDefault();
        }
        const target = event.target;
        const name = target.name;
        if(this.state.files[name]!=null){
            event.preventDefault();
            let shallowCopy = this.state.files;
            shallowCopy[name] = null;
            let nulls = []
            for(let i = 0; i<shallowCopy.length; i++){
                if(shallowCopy[i]===null){
                    nulls.push(i);
                }
            }
            console.log(nulls);
            for(let i = nulls.length-1; i>=0; i--){
                shallowCopy.splice(nulls[i], 1);
                console.log("removing "+nulls[i]);
                console.log(shallowCopy);
            }
            shallowCopy.push(null);
            this.setState({
                files: shallowCopy
            })
            console.log(shallowCopy);

           
        }


    }

    fileChanged(event) {
        console.log("filechanged Triggered");
        const target = event.target;
        const name = target.name;
        /* this.setState({
            [name]: event.target.files,
        });*/
        this.state.lock = true;
        new Compressor(event.target.files[0], {
            quality: 0.6,
            maxWidth: 1280,
            convertTypes: [
                'image/png',
                'image/webp',
                'image/gif'
            ],
            convertSize: 1, //always convert
            success: (compressed) => {
                console.log(compressed);

                const reader = new FileReader();
                reader.readAsDataURL(compressed);

                reader.onload = () => {
                    console.log(reader.result);
                    if(this.state.files[name]===undefined){
                        let shallowCopy = this.state.files;
                        shallowCopy.push(reader.result);
                        shallowCopy.push(null);
                        this.setState({
                            files: shallowCopy
                        });
                    }
            
                    if(this.state.files[name]===null){
                        let shallowCopy = this.state.files;
                        shallowCopy[name] = reader.result;
                        if(parseInt(name)===shallowCopy.length-1 && shallowCopy.length<3){
                            shallowCopy.push(null);
                            console.log("equal");
                        }
                        this.setState({
                            files:  shallowCopy
                        });
                    }
                    //console.log(this.state.files);
                    //console.log(URL.createObjectURL(this.state.files[name]));
                    this.state.lock = false;
                }
                reader.onerror = error => {
                    console.log(error);
                    this.state.lock = false;
                }
                
               
            },
            error: (err)=>{
                this.state.lock = false;
            }
        });

    }

    componentDidMount() {
        categoryServices.getCategories()
            .then((response) => {
                if (response.status === 401) {
                    throw new Error(401);
                } else if (response.status === 200) {
                    this.setState({ categories: response.data.content });
                    console.log(this.state.categories);
                    let curCat = this.state.categories.find(x => x.id_categoria == 1);
                    if(curCat!==undefined){
                        this.setState({
                            ccDesc: curCat.descrizione
                        })
                    }
                } else {
                    alert(response.data.message);
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem("userData");
                    alert("Your session has expired, please login again");
                    this.setState({goToLogin: true});
                } else {
                    alert("Error retrieving the categories, please try again.");
                }
            });

    }

    cancel() {
        this.setState({ goToRequest: true });
    }

    handleChange(event) {
        const target = event.target;
        const name = target.name;
        this.setState({
            [name]: event.target.value,
        });
        if(name === "category"){
            let curCat = this.state.categories.find(x => x.id_categoria == event.target.value);
            if(curCat!==undefined){
                this.setState({
                    ccDesc: curCat.descrizione
                })
            }
        }
        
    };

    handleSubmit(event) {
        this.setState({
            modalIsOpen: true
        });
        requestServices.postRequestsWPic(this.state.category, this.state.description, this.state.phone_number, this.state.reference,this.state.files).then((response) => {
            
            if (response.status === 200) {
                this.setState({ success: true });
                setTimeout(() => { this.setState({ confirmed: true }) }, 2000);
            } else {
                //alert(response.data.message);
                alert("Error processing your request, please try again.");
                this.setState({
                    modalIsOpen: false
                });
            }
        })
            .catch((error) => {
                this.setState({
                    modalIsOpen: false
                });
                if (error.response.status === 401) {
                    localStorage.removeItem("userData");
                    alert("Your session has expired, please login again");
                    this.setState({goToLogin: true});
                } else {
                    //localStorage.removeItem("userData");
                    //window.location.reload();
                    alert("Error processing your request, please try again.");
                }
            });
        //alert(this.state.username + " " + this.state.password);
        event.preventDefault();
    }

    render() {
        if (this.state.confirmed || this.state.goToRequest) {
            return <Navigate to='/' />;
        }
        if (this.state.goToLogin) {
            return <Navigate to='/' />;
        }
        
        
        let placeholder = "In order to provide the support service, it’s necessary to specify the serial number for the products for which the request is made; otherwise, if these are not present, we won’t be able to provide the desired support and the request will be ignored."
        
        let inputDivImages = <div name="inputFileDiv">
            {this.state.files.map((file, index) =>
                <div key={index} className={`${styles.fileInput} ${this.state.files[index] != null ? styles.active : ""}`} name={`${index}Div"`}>
                    <input type="file" accept="image/*" id={index} name={index} onChange={this.fileChanged} onClick={this.loadFile} className={styles.file} />
                    <label
                        style={this.state.files[index] != null ? { backgroundImage: "url(\"" + this.state.files[index] + "\")", backgroundSize: '100px' } : null}
                        htmlFor={index}>
                        {`${this.state.files[index] == null ? "➕ ADD" : "REMOVE"}`}
                    </label>
                </div>
            )}
        </div>

        
        return (
            <div>

                <Modal
                    isOpen={this.state.modalIsOpen}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    {this.state.success === true ? <div className={styles.external}><div className={styles.alert}> Request sent succesfully!</div></div> : <div>
                        <h2>Loading, please wait...</h2>
                        <div className={styles.spinCircle}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>}
                </Modal>
                <div className={styles.card}>
                    <div className={styles.cardContent}>
                        <div className={styles.cardTitle}>
                            <h2>INSERT REQUEST:</h2>
                            <div className={styles.underlineTitle}></div>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                            <label style={{ paddingTop: '13px' }}>
                                Category:
                            </label><br />
                            <select value={this.state.category} name="category" className={styles.formContent} onChange={this.handleChange}>
                                {this.state.categories === null ? '' : this.state.categories.map(option => <option key={option.id_categoria} value={option.id_categoria}>{option.nome}</option>)}
                            </select>
                            <div className={styles.formBorder}></div><br />
                            <div>Category detail: {this.state.ccDesc}</div>
                            <br />
                            <label style={{ paddingTop: '13px' }}>
                                Reference person:
                            </label><br />
                            <input name="reference" placeholder="Name and surname of the reference person for this request." className={styles.referencePersonContent} type="text" value={this.state.reference} maxlength="250" required onChange={this.handleChange} />
                            <br /><br />

                            <label style={{ paddingTop: '22px' }}>
                                Request description:
                            </label><br />
                            <textarea name="description" rows="12" className={styles.textareaR} placeholder={placeholder} maxLength="2500" value={this.state.description} required onChange={this.handleChange} /><br />
                            <div style={{ paddingTop: '22px' }}>
                                Phone number:
                            </div><br />
                            <input name="phone_number" className={styles.phoneContent} type="text" value={this.state.phone_number} maxlength="15" required onChange={this.handleChange} />
                            <br />
                            <div style={{ paddingTop: '22px' }}>
                                Pick up to 3 image to upload:
                            </div><br />

                            {inputDivImages}


                            {this.state.success === true ? <div className={styles.external}><div className={styles.alert}> Request sent succesfully!</div></div> : ''}
                            <input type="button" onClick={this.cancel} className={styles.cancelButton} style={{ marginRight: '10px', marginLeft: '10px' }} value="Cancel" />
                            <input className={styles.submitButton} style={{ marginRight: '10px', marginLeft: '10px' }} type="submit" value="Insert new request" />



                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default RequestForm;