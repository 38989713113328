import React from 'react';
import logo from '../../images/Galileo-Logo-Color.png';
import styles from './Header.module.css';
import Cookies from 'js-cookie';
import {isMobile, isChrome} from 'react-device-detect';


class Header extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        isLogged: false,
        redirectToLogout: false,
      };
      if (localStorage.getItem("userData") !== undefined) {
        if (localStorage.getItem("userData") !== null) {
          this.setState({isLogged: true});
        }
      }
      this.logout = this.logout.bind(this);
    }

    componentDidMount(){
      if (localStorage.getItem("userData") !== undefined) {
        if (localStorage.getItem("userData") !== null) {
          this.setState({isLogged: true});
        }
      }
      if(isMobile && !isChrome){
        let value = Cookies.get('mobile');
        if (value === undefined || value === null){
          const message = "We strongly recommend to use Chrome as mobile browser for this website: other browsers may not support every provided functionality.";
          alert(message);
          Cookies.set('mobile', 'true', { path: '' });
        }
      }
      

    }

    logout(){
      console.log('logout');
      window.location = '/logout';
    }

    render() {
      let logoutButton =  "";
      if(this.state.isLogged){
        logoutButton =  <input className={styles.imgLogout} onClick={this.logout} title="Logout" type="image" alt="logout" style={{padding:'10px', borderRadius: '50%'}} width={"20px"} src="/oof.png"/> ;
      }
      

      return (
        <div className={styles.divStyle}>
          <img alt="Galileo TP Process Equipment S.r.l." className={styles.imageStyle}  src={logo}/>
          <div className={styles.textStyle}>HELPDESK
          </div>
          <div className={styles.logoutDiv}> 
            {logoutButton}
          </div>
        </div>
      );
    }
  }


  export default Header;