import React from 'react';
import { Navigate } from 'react-router-dom';
import RequestForm from '../../components/RequestForm/RequestForm.js';
import styles from './RequestView.module.css';

class RequestView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: true,
      loadComponents: false
    }
    
  }
  componentDidMount(){
    if (localStorage.getItem("userData") === undefined) {
        this.setState({ authenticated: false }); 
        return;
    }
    if (localStorage.getItem("userData") === null) {
       this.setState({ authenticated: false }); 
       return;
    }
    this.setState({loadComponents: true});
  }  
  render() {
    const  redirect  = !this.state.authenticated;
    console.log(redirect);
    if (redirect) {
        alert("You must login before accessing this page!");
        return <Navigate to='/login'/>;
    }
    return (
      <div className={styles.fullDiv} style={{ height: '100%' }}>
        {this.state.loadComponents? <RequestForm /> : <></>}
      </div>
    );
  }
}

export default RequestView;