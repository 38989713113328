//import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import LoginView from './views/LoginView/LoginView';
import LogoutView from './views/LogoutView/LogoutView';
import RequestView from './views/RequestView/RequestView';
import RootView from './views/RootView/RootView';
import Header from './components/Header/Header';

function App() {

  return (
    <div className="App">
      <Header/>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginView />} />
          <Route path="/request" element={<RequestView />} />
          <Route path="/logout" element={<LogoutView />} />
          <Route path="/" element={<RootView />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
