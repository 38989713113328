import React from "react";
import { Navigate } from "react-router-dom";

import styles from './LoginForm.module.css';
const loginServices = require( '../../services/loginServices');
const authServices = require( '../../services/authServices');

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            username: '',
            password: '',
            success: false,
            error: false,
            redirect: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    

    handleChange(event) { 
        const target = event.target;
        const name = target.name;
        this.setState({
            [name]: event.target.value,
        })

        ;
    };
    
    handleSubmit(event) {
        loginServices.login(this.state.username,this.state.password).then((response)=>{
            if(response.status!==200){
                throw new Error(response.status);
            }
            //test
            authServices.auth().then((r2)=>{
                if(response.status!==200){
                    throw new Error(response.status);
                }
                //console.log(r2);
                localStorage.setItem("userData", JSON.stringify(r2.data));
                this.setState({success : true, error: false});
                setTimeout(()=>{window.location.reload();},2000);
            });
        })
        .catch((error) =>{
            this.setState({success : false, error: true});
            /*if(error === 401){
                localStorage.removeItem("userData");
                alert("Session expired, please login again...");
            }*/
        });
         //alert(this.state.username + " " + this.state.password);
        event.preventDefault();
    }


    render() {
        if(this.state.redirect){
            return <Navigate to='/'/>;
        }
        return (
            <div className={styles.card}>
                <div className={styles.cardContent}>
                    <div className={styles.cardTitle}>
                        <h2>LOGIN</h2>
                    <div className={styles.underlineTitle}></div>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <label style={{paddingTop:'13px'}}>
                            Username:                        </label> <br />
                            <input className={styles.formContent}  name="username" type="email" required value={this.state.username} onChange={this.handleChange} />

                        <div className={styles.formBorder}></div><br/>
                        <label style={{paddingTop:'22px'}}>
                            Password:
                            </label><br />
                            <input name="password" className={styles.formContent} type="password" value={this.state.password} required onChange={this.handleChange} />
                            <div className={styles.formBorder}></div>
                            <br/>
                            {this.state.success === true ?  <div className={styles.external}><div className={styles.alert}> Logged in succesfully!</div></div>  : ''}
                            {this.state.error === true ?  <div className={styles.external}><div className={styles.alertError}> Wrong credentials!</div></div>  : ''}
                        <input className={styles.submitButton} style={(this.state.success === true || this.state.error === true) ? {marginTop:  '20px'} : {marginTop: '50px'} } type="submit" value="Submit" />
                    </form>
                </div>
            </div>
        );
    }
}

export default LoginForm;