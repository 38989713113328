const axios = require('axios').default;
const endpoints =  require("./endpoints.js");
const jsReplacer = require("../utils/jsReplacer.js");

module.exports = {
    login: function login(username, password){
        return axios.request({
            url: endpoints.user+"/login",
            timeout: endpoints.backendTimeout,
            method: 'post',
            baseURL: endpoints.endpointUrl,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                email: username,
                password: password,
                os: "FRONTEND",
                version: endpoints.frontEndVersion
            },jsReplacer.jsonStringifyReplacer),
        });
    }
}