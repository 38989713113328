const axios = require('axios').default;
const endpoints =  require("./endpoints.js");

module.exports = {
    getCategories: function getCategories(){
        return axios.request({
            url: endpoints.category,
            timeout: endpoints.backendTimeout,
            method: 'get',
            baseURL: endpoints.endpointUrl,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }
}