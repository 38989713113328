import React from 'react';
import { Navigate } from 'react-router-dom';
import logoutServices from '../../services/logoutServices.js';


class LogoutView extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          redirect: false,
        }
        if((localStorage.getItem("userData") === null)||(localStorage.getItem("userData") === undefined)){
          this.setState({redirect: true});
        }
     }
      
    componentDidMount(){
      if((localStorage.getItem("userData") === null)||(localStorage.getItem("userData") === undefined)){
        this.setState({redirect: true});
      }
      console.log("loggin out...");
      if (localStorage.getItem("userData") !== undefined) {
           if (localStorage.getItem("userData") !== null) {
                localStorage.removeItem("userData");
           }
        }
        logoutServices.logout().then(()=>{
          if(!this.state.redirect){
            setTimeout(()=>{window.location.reload()},2000);
          }
        });
    }
    
    render() {
        if(!this.state.redirect){
            return <p style={{color: "white", fontWeight: "bold"}}>Loggin out, please wait...</p>;
        }
        return (
          <Navigate to="/login" />
        )
    }
  }


  export default LogoutView;